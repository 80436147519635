import React from "react";

const AdvertisingDisclosure = () => {
  return (
    <div className="App-content">
      <div class="_header_4a3ac">
        <h1>Advertising Disclosure</h1>
        <div class="_meta_4a3ac">Last Updated: March 1, 2022</div>
      </div>
      <div class="_body_4a3ac">
        <div class="_richText_7afec">
          <p>
            Elite Market Force, LLC is a marketing lead generator with its main
            office located at 109 E 17th Street #25, Cheyenne, WY 82001
          </p>
          <h3>Advertised Terms and Information</h3>
          <ul>
            <li>
              The information on this page relates to advertised terms made by
              or through Elite Market Force.
            </li>
            <li>
              Elite Market Force is not a lender, does not broker loans to
              lenders, and does not make loans or credit decisions. Elite Market
              Force is not an agent, representative, or broker of any lender and
              does not endorse or charge you for any service or product. All
              credit decisions, including loan approval and any conditional
              rates and terms you may be offered, are the responsibility of the
              lenders and will vary based upon your loan request, your
              particular financial situation, and criteria determined by the
              lenders making the offer.
            </li>
            <li>
              Not all consumers will qualify for the advertised rates and terms,
              and providing your information on this site does not guarantee
              that you will be approved for a loan.
            </li>
            <li>
              The rates and offers shown on this site are from entities in the
              Elite Market Force network. In some cases, Elite Market Force will
              not have a direct relationship with the entity, but will be able
              to access the rates via a third party partner.
            </li>
          </ul>
        </div>
        <h2>Additional Disclosures</h2>
        <ul class="_accordion_9133c">
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>General Advertising Disclosure</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Personal Loans</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>15-year Fixed Mortgage Rates</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Auto Refinance</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Insurance Products and Services</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Business Loans</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Student Loan Refinancing</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>How we display offers</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Where we operate</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Credit inquiries</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Information Sharing</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Credit Card Disclosure</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Government Programs</h3>
            </button>
          </li>
          <li class="_item_9133c">
            <button class="_title_9133c">
              <h3>Insurance Rates</h3>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdvertisingDisclosure;
