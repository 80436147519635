import React from "react";

const DoNotSellMPI = () => {
  return (
    <div className="App-content">
      <div class="_header_4a3ac">
        <h1>Do not sell my personal information</h1>
        <div class="_meta_4a3ac">Last Updated: March 3, 2021</div>
      </div>
      <div class="_body_4a3ac">
        <div class="_richText_7afec">
          <h2>California Consumer Privacy Act Opt-Out Choices</h2>
          <p>
            Like many companies online, we use services provided by Google,
            Facebook and others that help deliver interest-based ads to you.
            California law may classify our use of these services as a “sale” of
            your Personal Information (as defined in the California Consumer
            Privacy Act) to the companies that provide the services because we
            let them collect information from our users (e.g., device data and
            online activity data) to help them serve interest-based ads online.
            Your choices for limiting collection and/or use of your Personal
            Information by these companies include:
          </p>
          <p>
            <strong>Blocking cookies in your browser.</strong> Most browsers let
            you remove or reject cookies. To do this, follow the instructions in
            your browser settings. Many browsers accept cookies by default until
            you change your settings. Please note that if you set your browser
            to disable cookies, our sites may not work properly. For more
            information about cookies, including how to see what cookies have
            been set on your device and how to manage and delete them, visit{" "}
            <a href="http://www.allaboutcookies.org/">
              http://www.allaboutcookies.org
            </a>
            .
          </p>
          <p>
            <strong>
              Blocking advertising ID use in your mobile settings.
            </strong>{" "}
            Your mobile device settings may provide functionality to limit your
            use of the advertising ID associated with your mobile device for
            interest-based advertising purposes.
          </p>
          <p>
            <strong>Using privacy plug-ins or browsers.</strong> You can block
            cookies used for interest-based ads by using a browser with privacy
            features, like Brave, or installing browser plugins, like Privacy
            Badger, Ghostery or uBlock Origin, and configuring them to block
            third party cookies and other trackers.
          </p>
          <p>
            <strong>Platform opt-outs.</strong> The following advertising
            partners offer opt-out features that let you opt out of their use of
            your information for interest-based advertising:
          </p>
          <ul>
            <li>
              Google:{" "}
              <a href="https://adssettings.google.com/">
                https://adssettings.google.com
              </a>
            </li>
            <li>
              Facebook:{" "}
              <a href="https://www.facebook.com/about/ads">
                https://www.facebook.com/about/ads
              </a>
            </li>
          </ul>
          <p>
            Note that because these opt-out mechanisms are specific to the
            device or browser on which they are exercised, you will need to
            opt-out on every browser and device that you use.
          </p>
          <p>
            For information about your California privacy rights, click{" "}
            <a href="/privacy#CA">here</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DoNotSellMPI;
